<template>
	<!--用户信息-->
	<mainBox>
		<TSeach :topSearch="topSearch" v-model="seachData" @initSeach="initSeach">
			<template slot='btnSlot'>
				<div class="eventBtn rightBtnGroup">
					<el-button class="itBtn" type="primary" icon="el-icon-search" :loading="tbloading" @click="seach">{{$t("commons.search")}}</el-button><!-- 查询 -->
					<el-button class="itBtn" type="primary" icon="el-icon-plus"  @click="tbRowAdd">{{$t("commons.add")}}</el-button><!-- 添加 -->
				</div>
			</template>
		</TSeach>
		<!--<div class="tbBtns">
			<el-button class="itBtn" type="success" @click="tbRowAdd"  icon="el-icon-plus" size="mini" plain >添加</el-button>
		</div>-->
		<div class="table-body">
			<TTable ref="pointTable" :tbloading='tbloading'  :tableData="tableData" serialNumber :tableTitle="tableTitle" :tbParams="tableParams">
				<template slot="ruleStatus" slot-scope="scope">
					<el-switch v-model="scope.row.ruleStatus"  active-color="#409EFF" inactive-color="#ccc" active-value='ENABLE' inactive-value='DISABLE' @change='changeStatus(scope.row)'></el-switch>
				</template>
				<template slot='operating' slot-scope="scope">
					<el-button type="text"  @click.stop="tbRowCopy(scope.row)" >复制</el-button>
					<el-button type="text" v-if='!scope.row.isForbidden' @click.stop="tbRowDetail(scope.row)">查看</el-button>
					<el-button type="text" @click.stop="tbRowEdit(scope.row)" >编辑</el-button>
					<el-button type="text" v-if='scope.row.ruleStatus=="DISABLE"' @click.stop="tbRowDel(scope.row)" >删除</el-button>
				</template>
			</TTable>
		</div>
		<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
	</mainBox>
	
</template>

<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TSeach from '@/components/YTable/TSeach.vue';
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TSeach,TPagination,TForm},
		data() {
			return {
				addModel:{//新增，编辑的弹框信息
		      		open:false,
		      		editRowId:'',
		      	},
			};
		},
		created() {
			this.initTbConfig();
		},
		mounted() {},
		computed: {},
		methods: {
			initTbConfig(){
				const seachArr = [
			    	{name:'规则名称',type:"text",bindKeys:"ruleName",defaultValue:"",},
					{name:'规则状态',type:"select",bindKeys:"ruleStatus",defaultValue:'',option:[
						{label:'全部',value:''},
						{label:'启用',value:'ENABLE'},
						{label:'禁用',value:'DISABLE'},
					]},
				]
				const tableTitle = [
					{name:'规则名称',prop:"ruleName"},
					{name:'规则编码',prop:"ruleCode"},
					{name:'规则说明',prop:"description"},
					{name:'规则状态',prop:'ruleStatus',slotName:"ruleStatus",},
					{name:'创建人',prop:"creator"},
					{name:'创建时间',prop:"creationDateStr"},
				    {name:'操作',width:"180",fixed:'right',slotName:"operating",},
				];
				this.topSearch = seachArr;//参数需注入到minxs
				this.tableTitle = tableTitle;
			},
			async initTable(){
				let params = {
					...this.seachData,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
	  			this.tbloading=true;
	  			if(!params.ruleStatus) delete params.ruleStatus;
	  			let res = await this.ApiHttp('/order/rule/page',params);
	  			if(res){
	  				res.rows.map(it=>{
	  					it.creationDateStr=this.common.getTimeMmss(it.creationDate)
	  					
	  					
	  				})
	  				this.tableData = res.rows||[];
					this.tableParams.total = res.total;
					this.tbloading=false;
	  			}
				
			},
			tbRowAdd(){
				let params = {path: '/iot/rwork/ruleConfig/detail'}
				this.$router.push(params)
			},
			tbRowEdit(row){
				let params = {
					path: '/iot/rwork/ruleConfig/detail',
					query: {
						dataId: row.ruleId,
					}
				}
				this.$router.push(params)
			},
			tbRowDetail(row){
				let params = {
					path: '/iot/rwork/ruleConfig/detail',
					query: {
						isLook:true,
						dataId: row.ruleId,
					}
				}
				this.$router.push(params)
			},
			async tbRowCopy(row){
				//点击复制
				let params=JSON.parse(JSON.stringify(row));
				params.ruleName=params.ruleName+'-复制';
				params.ruleStatus='DISABLE';
				let res = await this.ApiHttp('/order/rule/create',params,'post');
				if(res){
					this.$message({type:'success',message:'操作成功'});
					this.initTable();
				}
				
//				let params={...row};
//	          	params.isForbidden=false;
//				this.disableOrEnable(params);
			},
			tbRowDel(row){
				this.$confirm('是否确认删除', '提示').then(() => {
					this.ApiHttp('/order/rule/delete/'+row.ruleId,{},'DELETE').then(res=>{
						this.$message({
				            type: 'success',
				            message: '删除成功'
			          	});
			          	this.initTable();
					});
		        }).catch(() => {});
				
			},
			changeStatus(row){
				let params={
				 	"ruleId":row.ruleId,
					"ruleStatus":row.ruleStatus,
				}
				this.ApiHttp('/order/rule/updateStatus',params,'post').then(res=>{
					this.initTable();
					this.$message({
			            type: 'success',
			            message: '操作成功'
		          	});
				});
			},
			disableOrEnable(params){
				//通过params执行启用或者禁用 isForbidden：1禁用0启用   
				this.ApiHttp('/organization/userInfo/update',params,'post').then(res=>{
					if(res){
						this.initTable();
						this.$message({
				            type: 'success',
				            message: '操作成功'
			          	});
					}
				});
			},
			closeDialog(isInit){
				//关闭弹框
				this.addModel={
		      		open:false,
		      		editRowId:'',
		      	};
		      	if(isInit){
		      		this.initTable();
		      	}
		      	
			},
		},
	};
</script>

<style scoped>
</style>